<template>
    <div class="pools lottoPool">
        <div class="buy">
            <div class="buy__item">
                <div class="label">Welcome to ASTRE's StarBall!</div>
            </div>
            <div class="buy__item" v-if="isOpen">
                <div class="text-highlight"><span>Win </span> {{winningPool.toFormat(0)}} STAR</div>
            </div>
            <div class="buy__item" v-if="!isOpen">
                <div class="text-highlight"><span>No Current StarBall</span></div>
            </div>
            <div class="buy__item">
                <div class="label">in today's lotto prizes. 
                </div>
            </div>
            <div class="buy__item label">
                    Ticket price: 100 STAR. Please SWAP first if you don’t have any STAR balance.
            </div>
            <div class="buy__item">
                <span class="label">( Your Balance: {{balance}} </span>
                <span class="token" style="padding:2px 3px">STAR</span>
                <span class="label"> )</span>
            </div>
            <div class="buy__item">
                <div class="ticket">
                    <div class="p1"></div>
                    <div class="p2">
                        <div class="dot"></div>
                    </div>
                    <div class="p3"></div>
                    <button v-if="!isMetaMaskConnected" @click="connectWallet">Connect Wallet</button>
                    <button v-if="isMetaMaskConnected && !isApproved" @click="approve" :disabled="!isOpen">Approve</button>
                    <button v-if="isMetaMaskConnected && isApproved" @click="game=true" :disabled="!isOpen || !canBuy">Get Your Ticket Now</button>
                </div>
            </div>
            <div class="buy__item">
                <div class="text-h6">
                    <span @click="$router.push('/results')" class="link">
                        Check Results & Your Tickets</span>
                </div>
            </div>
        </div>
        <q-dialog v-model="game">
            <q-card class="lotto_title q-px-sm q-py-sm">
                <q-card-section class="row items-center q-pb-none no-wrap">
                    <div class="q-px-lg text-white pick" >
                        Pick 6 Lucky Balls to Buy your Ticket</div>
                    <q-space/>
                    <q-btn icon="close" flat round dense v-close-popup class="q-p-none"></q-btn>
                </q-card-section>
                <q-card-section style="padding:0">
                    <ticket-game></ticket-game>
                </q-card-section>
            </q-card>
        </q-dialog>
        <div class="get" v-if="isOpen">
            <div class="get__item">
                <div class="title text-white">Try Your Luck Now With the DeFi StarBall</div>
            </div>
            <div class="get__item q-my-md">
                <div class="text-clock">Next draw in <span>{{remaindHours}}</span> <span>hours</span> {{remaindMinutes}} <span> minutes</span></div>
            </div>
            <div class="get__item">
                <div class="card">
                    <div class="card__item card__item--header">
                        <div style="font-size: 1.2rem; font-weight: bold;">Next StarBall</div>
                        <div>#{{parseInt($store.state.lottery.currentLotteryId)}} | Draw: {{nextDrawTime}} </div>
                    </div>
                    <div class="card__item">
                        <div style="width:100%;">
                            <div class="item row" style="width:100%;">
                                <div class="item__title col-md-3 col-sm-12" style="width:100%">Winnings Pool</div>
                                <div class="col-md-9 col-sm-12" style="width:100%;">
                                    <div class="value">{{this.getDecimalData(winningPoolAmount)}} STAR</div>

                                </div>
                            </div>
                            <div class="item row" v-if="isMetaMaskConnected && isApproved">
                                <div class="item__title col-md-3 col-sm-12 q-mb-md" style="width:100%">Get your ticket</div>
                                <div class="col-md-9 col-sm-12">
                                    <button class="buy-buttom " @click="game=true" :disabled="!isOpen || !canBuy"> Click Here</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="cardExpand" class="card__item">
                        <div style="width:100%;">
                            <div class="label">Match all 6 or upto any 3 balls in the results to win. Current prizes up for
                                grabs:
                            </div>
                            <div class="match-items justify-center">
                                <div class="match-items__item text-center">
                                    <div class="title">Match all 6</div>
                                    <div class="value">{{this.getDecimalData(match6PrizeSTAR)}} <span class="token">STAR</span></div>
                                </div>
                                <div class="match-items__item text-center">
                                    <div class="title">Match any 5</div>
                                    <div class="value">{{this.getDecimalData(match5PrizeSTAR)}} <span class="token">STAR</span></div>
                                </div>
                                <div class="match-items__item text-center">
                                    <div class="title">Match any 4</div>
                                    <div class="value">{{this.getDecimalData(match4PrizeSTAR)}} <span class="token">STAR</span></div>
                                </div>
                                <div class="match-items__item text-center">
                                    <div class="title">Match any 3</div>
                                    <div class="value">{{this.getDecimalData(match3PrizeSTAR)}} <span class="token">STAR</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card__item" style="justify-content: center;">
                        <div v-if="cardExpand && false" class="card__expand__switcher" @click="cardExpand = !cardExpand">Hide
                            <q-icon name="expand_less" color="info"/>
                        </div>
                        <div v-if="!cardExpand && false" class="card__expand__switcher" @click="cardExpand = !cardExpand">Show
                            <q-icon name="expand_more" color="info"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BigNumber from 'bignumber.js';
    import TicketGame from "../ticket-game";

    export default {
        components: {TicketGame},
        data() {
            return {
                //cardExpand: true,
                game: false,
                banner: true,
                remaind: 0,
                updateInterval: null
            }
        },
        computed: {
            isOpen() {
                if(!this.$store.state.lottery.currentLotteryId)
                    return false;
                if(this.$store.state.lottery.nextDraw<new Date())
                    return false;
                return this.$store.state.lottery.currentLotteryState==1;
            },
            canBuy() {
                if(BigNumber(this.$store.state.balance.amountSTAR)
                    .isGreaterThan(BigNumber(this.$store.state.lottery.price)))
                    return true;
                return false;
            },
            isMetaMaskConnected() {
                return this.$store.state.account!=null;
            },
            isApproved() {
                return this.$store.state.approvedLottery;
            },
            balance() {
                if(!BigNumber(this.$store.state.balance.amountSTAR).isNaN())
                    return BigNumber(this.$store.state.balance.amountSTAR).shiftedBy(-18).toFixed(3)
                else
                    return BigNumber(0);
            },
            cardExpand() {
                return this.winningPool.isGreaterThan(0);
            },
            remaindHours() {
                return parseInt(this.remaind / 3600);
            },
            remaindMinutes() {
                return parseInt((this.remaind / 60) % 60);
            },
            nextDrawTime() {
                const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
                const dtf = new Intl.DateTimeFormat('en-US', options);
                return dtf.format(this.$store.state.lottery.nextDraw);
            },
            rateUSD() {
                return this.$store.state.price.rateSTAR;
            },
           winningPoolAmount() {
                if (BigNumber(this.winningPoolSTAR).isGreaterThan(0))
                    return BigNumber(this.$store.state.lottery.winningPrize).shiftedBy(-18);
                else
                    return 0;
            },
            winningPool() {
                if (BigNumber(this.winningPoolSTAR).isGreaterThan(0))
                    return BigNumber(this.$store.state.lottery.winningPrize).shiftedBy(-18);
                else
                    return 0;
            },
            winningPoolSTAR() {
                if(!BigNumber(this.$store.state.lottery.winningPrize).isNaN())
                    return this.$store.state.lottery.winningPrize.shiftedBy(-18);
                else
                    return BigNumber(0);
            },
            match6PrizeSTAR() {
                return BigNumber(this.winningPoolSTAR).times(0.5);
            },
            match5PrizeSTAR() {
                return BigNumber(this.winningPoolSTAR).times(0.25);
            },
            match4PrizeSTAR() {
                return BigNumber(this.winningPoolSTAR).times(0.15);
            },
            match3PrizeSTAR() {
                return BigNumber(this.winningPoolSTAR).times(0.1);
            },
        },
        mounted() {
            this.$store.commit('read_lottery');

            if(this.$store.state.lottery.nextDraw) {
                this.remaind = (new Date(this.$store.state.lottery.nextDraw)-new Date)/1000;
                this.updateInterval = setInterval(()=>{
                    this.remaind = (new Date(this.$store.state.lottery.nextDraw)-new Date)/1000
                },1000);
            }
        },
        destroyed() {
            if (this.updateInterval != null) {
                clearInterval(this.updateInterval);
            }
        },
        methods: {
            approve()  {
                this.$store.dispatch('approveLottery');
            },
            connectWallet() {
                this.$store.dispatch('connect');
            },
            getDecimalData(value) {
                let limit_xxx = BigNumber(1000);
                let limit_xx = BigNumber(1);
                let limit_x = BigNumber(1).shiftedBy(-3);
                let limit_sm = BigNumber(1).shiftedBy(-6);
                let limit_md = BigNumber(1).shiftedBy(-9);
                let limit_lg = BigNumber(1).shiftedBy(-12);

                if (limit_lg.isGreaterThan(value)) {
                    return value.toString();
                }
                else if (limit_md.isGreaterThan(value)) {
                    return value.decimalPlaces(12, 1).toString();
                }
                else if (limit_sm.isGreaterThan(value)) {
                    return value.decimalPlaces(9, 1).toString();
                }
                else if (limit_x.isGreaterThan(value)) {
                    return value.decimalPlaces(6, 1).toString();
                }
                else if (limit_xx.isGreaterThan(value)) {
                    return value.decimalPlaces(4, 1).toString();
                }
                else if (limit_xxx.isGreaterThan(value)) {
                    return value.decimalPlaces(2, 1).toString();
                }
                else {
                    return value.decimalPlaces(0, 1).toString();
                }
            }
        }
    }
</script>

<style scoped>
    .q-card {
        background: radial-gradient(#03a9f4, #111112);
    }

    lottoPool div.text-white {
        font-size: 1rem;
        font-weight: bold;
    }

    .text-highlight {
        font-size: 3rem;
        font-weight: bold;
        color: var(--q-color-warning)
    }

    .title {
        font-size: 2rem;
        font-weight: bold;
    }

    .text-clock {
        font-size: 1.5rem;
        color: var(--q-color-warning);
    }

    .buy {
        padding: 0;
        display: flex;
        flex-direction: column;
        background-position: center -2rem;
        background-repeat: no-repeat;
    }

    .buy__item {
        padding: 15px;
        display: flex;
        justify-content: center;
    }

    .buy__item button {
        background-color: var(--q-color-primary);
        border: none;
        padding: 10px 80px;
        color: #fff;
        font-weight: bold;
        border-radius: 10px;
        z-index: 10;
        cursor: pointer;
    }

    .buy__item button:hover {
        opacity: .8;
    }

    .ticket {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 20px;
        border-radius: 10px;
    }

    .ticket > .p1 {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 20%;
        background-color: var(--q-color-warning);
        border-radius: 10px 0 0 10px;
    }

    .ticket > .p2 {
        position: absolute;
        top: 0;
        left: 20%;
        right: 70%;
        bottom: 0;
        background: radial-gradient(circle at 100% 100%, rgba(204, 0, 0, 0) 9px, var(--q-color-warning) 10px),
        radial-gradient(circle at 0 100%, rgba(204, 0, 0, 0) 9px, var(--q-color-warning) 10px),
        radial-gradient(circle at 0 0, rgba(204, 0, 0, 0) 9px, var(--q-color-warning) 10px),
        radial-gradient(circle at 100% 0, rgba(204, 0, 0, 0) 9px, var(--q-color-warning) 10px);
        background-position: bottom left, bottom right, top right, top left;
        background-size: 50% 50%;
        background-repeat: no-repeat;
        padding: 14px;
    }

    .ticket > .p2 .dot {
        border-right: 3px dotted gray;
        height: 100%;
        margin-left: -1.5px;
        /* z-index: 100; */
    }

    .ticket > .p3 {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 70%;
        background-color: var(--q-color-warning);
        border-radius: 0 10px 10px 0;
    }

    .get {
        display: flex;
        flex-direction: column;
        padding: 100px 0;
        background-color: var(--q-color-cloud);
    }

    .get__item {
        display: flex;
        justify-content: center;
        padding: 20px;
    }

    /* .card {
        width: 100%;
        max-width: 700px;
        border:1px solid hsl(0deg 0% 100% / 40%);
        border-radius: 10px;
        box-shadow: 0px 0px 3px 0.1px rgb(0, 0, 0);
    } */
/* 
    .card__item {
        padding: 25px;
        background-color: #fff;
        color: #333;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .card__item--header {
        background: #f1f1f1;
    } */

    .card__item{
        padding: 25px;
        color: rgb(255, 255, 255);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    .card__item--header{
        background: #f1f1f1;
        background-color: #0000007a;
        border-bottom: 1px solid hsl(0deg 0% 100% / 20%);
    }

    .card__item:first-child {
        border-radius: 10px 10px 0 0;
    }

    .card__item:last-child {
        border-radius: 0 0 10px 10px;
    }

    .card__item .item {
        display: flex;
        padding: 5px 0;
    }

    .card__item .item .item__title {
        font-size: 1.2rem;
        font-weight: bold;
        width: 150px;
        margin-top:7px;
    }

    .card__item .item .value {
        font-size: 2rem;
        font-weight: bold;
        color: var(--q-color-info);
    }

    .card__item .item .sub-value {
        color: #666;
    }

    .card__item .item .buy-buttom {
        padding: 10px 25px;
        border: none;
        background-color: var(--q-color-info);
        color: #fff;
        font-weight: bold;
        border-radius: 10px;
        box-shadow: 1px 1px 2px .2px rgb(27, 25, 25);
        cursor: pointer;
    }

    /* .card__item .item .buy-buttom:hover {
        opacity: .8 !important;
    } */

    .match-items {
        display: flex;
        flex-wrap: wrap;
    }

    .match-items__item {
        padding: 10px;
        margin: 5px;
    }

    .match-items__item .title {
        font-size: 1rem;
        color: var(--q-color-info);
    }

    .match-items__item .title--alt {
        font-size: 1rem;
        color: var(--q-color-accent);
    }

    .match-items__item .value {
        font-size: 1.2rem;
        font-weight: 500;
    }

    .match-items__item .sub-value {
        font-size: .8rem;
        color: #666;
    }

    .card__expand__switcher {
        font-size: 1rem;
        font-weight: bold;
        color: var(--q-color-info);
        cursor: pointer;
    }

    .link{
        color: #fff;
        margin: 5px 0;
        text-decoration: none;
        cursor:pointer
    }

    .link:hover{
        color: rgb(255, 255, 0);
    }

    .lotto_title >>> .row {
        margin:0 !important;
        padding: 0 !important;
    }

    .pick {
        font-size:1.3rem;
    }
    @media (max-width: 650px) {
        .text-highlight {
            font-size: 2rem;
        }

        .pick {
            font-size:0.8rem;
        }

        .buy__item button {
            padding: 10px 10px !important;
        }

        .get__item {
            padding:0;
        }

        .card__item .item .value[data-v-78d0b832] {
            font-size: 1.4rem;
        }

        .match-items__item .value .token{
            font-size: 1rem !important;
            font-weight: 500;
        }
    }
</style>