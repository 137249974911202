<template>
    <div>
        <div class="mainContent">
            <div class="orangeBar">
                <template v-for="(v,i) in show">
                    <a href="#" @click="clearNumber(i)" :key="i">{{v}}</a>
                </template>
            </div>
            <div class="numberBar">
                <div class="row1">
                    <a class="stage" @click="setNumber(1)">
                        <lotteryball number="1" mark="one"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(2)">
                        <lotteryball number="2" mark="two"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(3)">
                        <lotteryball number="3" mark="three"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(4)">
                        <lotteryball number="4" mark="four" class="one"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(5)">
                        <lotteryball number="5" mark="five"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(6)">
                        <lotteryball number="6" mark="six"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(7)">
                        <lotteryball number="7" mark="seven"></lotteryball>
                    </a>
                </div>
                <div class="row2">
                    <a class="stage" @click="setNumber(8)">
                        <lotteryball number="8" mark="eight"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(9)">
                        <lotteryball number="9" mark="nine"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(10)">
                        <lotteryball number="10" mark="ten"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(11)">
                        <lotteryball number="11" mark="eleven"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(12)">
                        <lotteryball number="12" mark="twelve"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(13)">
                        <lotteryball number="13" mark="thirteen"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(14)">
                        <lotteryball number="14" mark="fourteen"></lotteryball>
                    </a>
                </div>
                <div class="row3">
                    <a class="stage" @click="setNumber(15)">
                        <lotteryball number="15" mark="fifteen"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(16)">
                        <lotteryball number="16" mark="sixteen"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(17)">
                        <lotteryball number="17" mark="seventeen"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(18)">
                        <lotteryball number="18" mark="eighteen"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(19)">
                        <lotteryball number="19" mark="nineteen"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(20)">
                        <lotteryball number="20" mark="twenty"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(21)">
                        <lotteryball number="21" mark="tOne"></lotteryball>
                    </a>
                </div>
                <div class="row4">
                    <a class="stage" @click="setNumber(22)">
                        <lotteryball number="22" mark="ttwo"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(23)">
                        <lotteryball number="23" mark="tthree"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(24)">
                        <lotteryball number="24" mark="tfour"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(25)">
                        <lotteryball number="25" mark="tfive"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(26)">
                        <lotteryball number="26" mark="tSix"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(27)">
                        <lotteryball number="27" mark="tSeven"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(28)">
                        <lotteryball number="28" mark="tEight"></lotteryball>
                    </a>
                </div>
                <div class="row5">
                    <a class="stage" @click="setNumber(29)">
                        <lotteryball number="29" mark="tNine"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(30)">
                        <lotteryball number="30" mark="thirty"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(31)">
                        <lotteryball number="31" mark="thOne"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(32)">
                        <lotteryball number="32" mark="thtwo"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(33)">
                        <lotteryball number="33" mark="ththree"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(34)">
                        <lotteryball number="34" mark="thfour"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(35)">
                        <lotteryball number="35" mark="thfive"></lotteryball>
                    </a>
                </div>
                <div class="row6">
                    <a class="stage" @click="setNumber(36)">
                        <lotteryball number="36" mark="thSix"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(37)">
                        <lotteryball number="37" mark="thSeven"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(38)">
                        <lotteryball number="38" mark="thEight"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(39)">
                        <lotteryball number="39" mark="thNine"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(40)">
                        <lotteryball number="40" mark="fourty"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(41)">
                        <lotteryball number="41" mark="fOne"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(42)">
                        <lotteryball number="42" mark="ftwo"></lotteryball>
                    </a>
                </div>
                <div class="row7">
                    <a class="stage" @click="setNumber(43)">
                        <lotteryball number="43" mark="fthree"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(44)">
                        <lotteryball number="44" mark="ffour"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(45)">
                        <lotteryball number="45" mark="ffive"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(46)">
                        <lotteryball number="46" mark="fSix"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(47)">
                        <lotteryball number="47" mark="fSeven"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(48)">
                        <lotteryball number="48" mark="fEight"></lotteryball>
                    </a>
                    <a class="stage" @click="setNumber(49)">
                        <lotteryball number="49" mark="fNine"></lotteryball>
                    </a>
                </div>
            </div>
            <div class="row8 q-my-sm">
                <a class="redBtn" @click="reset()">Reset</a>
                <a class="greenBtn" @click="getRandom()">Random</a>
                <a class="blueBtn" @click="sendData()">Submit</a>
            </div>
        </div>
        <div id="successMsg" v-if="successMsg!=null">{{successMsg}}</div>
        <div id="errorMsg" v-if="errorMsg!=null">{{errorMsg}}</div>
    </div>
</template>

<script>
    import lotteryball from '@/components/lottery/lotteryball'
    export default {
        name: "ticket-game",
        components: {
            lotteryball,
        },
        data() {
            return {
                show: [0, 0, 0, 0, 0, 0],
                number: [0, 0, 0, 0, 0, 0],
                position: 0,
                successMsg: null,
                errorMsg: null
            }
        },
        created() {
            this.position = 0;
        },
        computed: {},
        methods: {
            countEmpty() {
                let cnt = 0;
                for (let index = 0; index < 6; index++) {
                    cnt += (this.number[index] === 0);
                }
                return cnt;
            },
            clearNumber(index) {
                this.number[index] = 0;
                this.position = index;
                this.update();
            },
            setNumber(num) {
                if (this.number.includes(num)) {
                    this.errorMsg = "Can't select same ball!";
                    setTimeout(() => {
                        this.errorMsg = null;
                    }, 2000);
                } else {
                    if (this.position < 6) {
                        this.number[this.position++] = num;
                    }
                    if (this.position === 6) {
                        if (this.countEmpty() === 0) {
                            this.successMsg = "Great! You've selected your 6 numbers";
                            setTimeout(() => {
                                this.successMsg = null
                            }, 1000);
                        }
                    }
                    this.update();
                }

            },
            update() {
                this.show = [];
                for (let index = 0; index < 6; index++) {
                    this.show[index] = this.number[index];
                }
            },
            reset() {
                this.number = [0, 0, 0, 0, 0, 0];
                this.position = 0;
                this.update();
            },
            sendData() {
                if (this.countEmpty() > 0) {
                    this.errorMsg = "Please select all 6 numbers";
                    setTimeout(() => {
                        this.errorMsg = null
                    }, 3000);
                } else {
                    this.$store.dispatch('buyTicket', this.number);
                }
            },
            getRandom() {
                var arr = [];
                while (arr.length < 6) {
                    var r = Math.floor(Math.random() * 49) + 1;
                    if (arr.indexOf(r) === -1) arr.push(r);
                }
                this.number = arr;
                this.position = 6;
                this.update();
            }
        }
    }
</script>

<style scoped>
    .mainContent {
        max-width: 880px;
        margin: auto;
        border-radius: 0;
        padding: 10px 15px;
    }

    .mainContent .orangeBar {
        background-color: #314682;
        width: 100%;
        text-align: center;
        padding: 8px 0;
        border-radius: 5px;
        -webkit-box-shadow: 0 1px 4px 0 #969696;
        box-shadow: 0 1px 4px 0 #969696;
        margin-bottom: 10px;
    }

    .mainContent .orangeBar a {
        width: 50px;
        height: 50px;
        display: inline-block;
        border-radius: 100%;
        background: radial-gradient(circle at 60% 160%, #9a9a9a, #f9f9f9 80%, #ffffff 100%);
        margin-right: 15px;
        line-height: 50px;
        text-decoration: none;
        color: #000;
        font-size: 16px;
    }

    .mainContent .numberBar a:last-child,
    .mainContent .orangeBar a:last-child {
        margin-right: 0px;
    }

    .mainContent .numberBar {
        padding-top: 5px;
    }

    .mainContent .numberBar div {
        display: flex;
        justify-content: center;
    }

    .mainContent .numberBar a {
        margin: 0 5px;
    }

    .mainContent .row8 {
        text-align: center;
        display: flex;
        justify-content: space-between;
    }

    .mainContent .row8 a {
        width: calc((100% - 40px) / 3);
        height: 40px;
        display: inline-block;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 900;
        line-height: 40px;
        text-decoration: none;
        color: #fff;
        text-align: center;
        cursor: pointer;
        transition: 0.2s all linear;
    }

    .mainContent .row8 a.redBtn {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e76843+0,e2491f+39,790d0a+100 */
        background: rgb(231, 104, 67); /* Old browsers */
        background: -moz-linear-gradient(top, rgba(231, 104, 67, 1) 0%, rgba(226, 73, 31, 1) 39%, rgba(121, 13, 10, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(231, 104, 67, 1) 0%, rgba(226, 73, 31, 1) 39%, rgba(121, 13, 10, 1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(231, 104, 67, 1) 0%, rgba(226, 73, 31, 1) 39%, rgba(121, 13, 10, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e76843', endColorstr='#790d0a', GradientType=0); /* IE6-9 */
    }

    .mainContent .row8 a.redBtn:hover {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#790d0a+0,e2491f+61,e76843+100 */
        background: rgb(121, 13, 10); /* Old browsers */
        background: -moz-linear-gradient(top, rgba(121, 13, 10, 1) 0%, rgba(226, 73, 31, 1) 61%, rgba(231, 104, 67, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(121, 13, 10, 1) 0%, rgba(226, 73, 31, 1) 61%, rgba(231, 104, 67, 1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(121, 13, 10, 1) 0%, rgba(226, 73, 31, 1) 61%, rgba(231, 104, 67, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#790d0a', endColorstr='#e76843', GradientType=0); /* IE6-9 */

    }

    .mainContent .row8 a.greenBtn {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3dc462+0,169039+47,034d28+100 */
        background: rgb(61, 196, 98); /* Old browsers */
        background: -moz-linear-gradient(top, rgba(61, 196, 98, 1) 0%, rgba(22, 144, 57, 1) 47%, rgba(3, 77, 40, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(61, 196, 98, 1) 0%, rgba(22, 144, 57, 1) 47%, rgba(3, 77, 40, 1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(61, 196, 98, 1) 0%, rgba(22, 144, 57, 1) 47%, rgba(3, 77, 40, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3dc462', endColorstr='#034d28', GradientType=0); /* IE6-9 */
    }

    .mainContent .row8 a.greenBtn:hover {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#034d28+0,169039+53,3dc462+100 */
        background: rgb(3, 77, 40); /* Old browsers */
        background: -moz-linear-gradient(top, rgba(3, 77, 40, 1) 0%, rgba(22, 144, 57, 1) 53%, rgba(61, 196, 98, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(3, 77, 40, 1) 0%, rgba(22, 144, 57, 1) 53%, rgba(61, 196, 98, 1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(3, 77, 40, 1) 0%, rgba(22, 144, 57, 1) 53%, rgba(61, 196, 98, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#034d28', endColorstr='#3dc462', GradientType=0); /* IE6-9 */

    }

    .mainContent .row8 a.blueBtn {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1ba2d2+0,0e6ba0+47,05497f+100 */
        background: rgb(27, 162, 210); /* Old browsers */
        background: -moz-linear-gradient(top, rgba(27, 162, 210, 1) 0%, rgba(14, 107, 160, 1) 47%, rgba(5, 73, 127, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(27, 162, 210, 1) 0%, rgba(14, 107, 160, 1) 47%, rgba(5, 73, 127, 1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(27, 162, 210, 1) 0%, rgba(14, 107, 160, 1) 47%, rgba(5, 73, 127, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1ba2d2', endColorstr='#05497f', GradientType=0); /* IE6-9 */
    }

    .mainContent .row8 a.blueBtn:hover {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#05497f+0,0e6ba0+53,1ba2d2+100 */
        background: rgb(5, 73, 127); /* Old browsers */
        background: -moz-linear-gradient(top, rgba(5, 73, 127, 1) 0%, rgba(14, 107, 160, 1) 53%, rgba(27, 162, 210, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(5, 73, 127, 1) 0%, rgba(14, 107, 160, 1) 53%, rgba(27, 162, 210, 1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(5, 73, 127, 1) 0%, rgba(14, 107, 160, 1) 53%, rgba(27, 162, 210, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#05497f', endColorstr='#1ba2d2', GradientType=0); /* IE6-9 */
    }

    #successMsg,
    #errorMsg {
        width: 600px;
        margin: 10px auto;
        border-left: #000 solid 5px;
        border-right: #000 solid 5px;
        padding: 15px 20px;
        background-color: #000;
        color: #fff;
        font-weight: 500;
        font-size: 20px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    #successMsg {
        background-color: #169039;
        border-left-color: #034D28;
        border-right-color: #034D28;
    }

    #errorMsg {
        background-color: #BE131D;
        border-left-color: #790D0A;
        border-right-color: #790D0A;
    }

    .main_ball:hover {
        transform: scale(1.1);
    }

    .stage {
        position: relative;
        cursor: pointer;
        display: block;
        width: 50px;
        height: 50px;
        -webkit-perspective: 1200px;
        -moz-perspective: 1200px;
        -ms-perspective: 1200px;
        -o-perspective: 1200px;
        perspective: 1200px;
        -webkit-perspective-origin: 50% 50%;
        -moz-perspective-origin: 50% 50%;
        -ms-perspective-origin: 50% 50%;
        -o-perspective-origin: 50% 50%;
        perspective-origin: 50% 50%;
    }

    .mainContent .numberBar .row1,
    .mainContent .numberBar .row2,
    .mainContent .numberBar .row4,
    .mainContent .numberBar .row5
    {
        padding-bottom: 2px;
    }
    .mainContent .numberBar .row3,
    .mainContent .numberBar .row6,
    .mainContent .numberBar .row7 {
        padding-bottom: 2px;
    }

    @media (max-width: 650px) {
        .mainContent .orangeBar a {
            width: 35px !important;
            height: 35px !important;
            margin-right: 10px;
            line-height: 35px !important;
            text-decoration: none;
            color: #000;
            font-size: 16px;
        }

        .mainContent .numberBar a {
            margin: 1px 2px;
        }

        .stage {
            width: 42px;
            height: 42px;
        }
    }
</style>