<template>
    <div class="main_ball" :class="mark">
        <div class="se_ball" :class="mark">
           <div class="tr_ball" :class="mark">
                <div class="la_ball" :class="mark">
                   <div class="fo_ball" :class="mark">
                        <p>{{this.number}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        data() {
            return {
            };
        },
        props: {
            number:null,
            mark:null
        },
    };
</script>

<style scoped>
    .main_ball {
        width: 45px;
        height: 45px;
        background: radial-gradient(circle at 30px 30px, #651a72 25%, darkmagenta 93%, #8b008bd9 5% );
        border-radius: 50%;
        box-shadow: 0px 2px 0px 1px #0b0b0b26;
        float: left;
        margin-right: 5px;
        margin-bottom: 27px;
    }

    .se_ball {
        width: 36px;
        height: 36px;
        background: linear-gradient(221deg, #ffffff63 10%, #cfcdcd36 40%, #58575780 126%);
        border-radius: 50%;
        position: absolute;
        margin-top: 4px;
        margin-left: 1px;
        filter: brightness(1);
        filter: blur(0vmax);
    }

    .tr_ball {
        width: 30px;
        height: 30px;
        background: radial-gradient(circle at 24px 25px, white, #f7f5f5e3);
        border-radius: 50%;
        margin-top: 3px;
        position: absolute;
        margin-left: 3px;
    }

    .la_ball {
        height: 26px;
        width: 26px;
        background: #cc4ae2;
        border-radius: 50%;
        position: absolute;
        margin-left: 2px;
        margin-top: 2px;
        background: darkolivegreen;
        background: darkmagenta;
    }

    .fo_ball {
        border-radius: 50%;
        background: linear-gradient(142deg, #fdfdfdfa, #fffcfc, white);
        width: 22px;
        height: 22px;
        margin-top: 2px;
        margin-left: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    p {
        font-size: 14px;
        font-family: monospace;
        font-weight: 900;
        position: absolute;
        margin: 0;
        color:black;
    }

    .\33 d_Ball_all {
        max-width: 100%;
        height: auto;
        overflow: hidden;
    }

    .\33 d_Ball_all_wrapper {
        width: 1180px;
        margin: auto;
    }

    .container {
        width: 100%;
        margin: auto;
    }

    .main_ball:last-child {
        margin-right: 0px;
    }

    .\33 d_Ball_all_wrapper:nth-child(7) {
        margin-left: 0px;
    }

    /************ball color change start****************/

    .main_ball.two,
    .main_ball.tOne,
    .main_ball.thtwo,
    .main_ball.ftwo {
        background: radial-gradient(circle at 40px 40px, red 25%, darkmagenta 93%, #8b008bd9 5% );
    }

    .la_ball.two,
    .la_ball.tOne,
    .la_ball.thtwo,
    .la_ball.ftwo {
        background: radial-gradient(#f42e30, #f50d0d9c);
    }

    .main_ball.three,
    .main_ball.three,
    .main_ball.tthree,
    .main_ball.fthree {
        background: radial-gradient(circle at 40px 40px, blue 25%, darkmagenta 93%, white 5% );
    }

    .la_ball.three,
    .la_ball.three,
    .la_ball.tthree,
    .la_ball.three {
        background: radial-gradient(#1500ed, #2c2b30a6);
    }

    .main_ball.four,
    .main_ball.tfour,
    .main_ball.ffour {
        background: radial-gradient(circle at 40px 40px, #0e0d0d 25%, #3a383a 93%, #8b008bd9 5% );
    }

    .la_ball.four,
    .la_ball.tfour,
    .la_ball.ffour {
        background: radial-gradient(black, #251d248f);
    }

    .main_ball.five,
    .main_ball.eighteen,
    .main_ball.tfive,
    .main_ball.thfive,
    .main_ball.ffive {
        background: radial-gradient(circle at 40px 40px, #1a6272 25%, #00658b 93%, #000000 5% );
    }

    .la_ball.five,
    .la_ball.eighteen,
    .la_ball.tfive,
    .la_ball.thfive,
    .la_ball.ffive {
        background: radial-gradient(#00bcd4, #000000b3);
    }

    .main_ball.six,
    .main_ball.sixteen,
    .main_ball.tSix,
    .main_ball.thSix,
    .main_ball.fSix {
        background: radial-gradient(circle at 40px 40px, #ffeb3b 25%, #402e40 93%, #8b008bd9 5% );
    }

    .la_ball.six,
    .la_ball.sixteen,
    .la_ball.tSix,
    .la_ball.thSix,
    .la_ball.fSix {
        background: radial-gradient(#e2e909, #302f288f);
    }


    .main_ball.seven,
    .main_ball.seventeen,
    .main_ball.tSeven,
    .main_ball.fSeven {
        background: radial-gradient(circle at 40px 40px, #03a9f4 25%, #403440 93%, #8b008bd9 5% );
    }

    .la_ball.seven,
    .la_ball.seventeen,
    .la_ball.tSeven,
    .la_ball.fSeven {
        background
        : radial-gradient(#099de1, #00000080);
    }

    .main_ball.eight,
    .main_ball.tEight,
    .main_ball.thEight,
    .main_ball.fEight {
        background: 70% radial-gradient(#121211, transparent);
        background: radial-gradient(circle at 40px 40px, #029600 25%, #4a314a 93%, #8b008bd9 5% );
    }

    .la_ball.eight,
    .la_ball.tEight,
    .la_ball.thEight,
    .la_ball.fEight {
        background: radial-gradient(#2f9e2d, #000000b0);
    }

    .main_ball.nine,
    .main_ball.nineteen,
    .main_ball.tNine,
    .main_ball.fNine {
        background: radial-gradient(circle at 40px 40px, #07ffdd 25%, #4c3e4c 93%, #8b008bd9 5% );
    }

    .la_ball.nine,
    .la_ball.nineteen,
    .la_ball.tNine,
    .la_ball.fNine {
        background: radial-gradient(#34e9d1, #00000080);
    }

    .main_ball.ten,
    .main_ball.thirty,
    .main_ball.thNine {
        background: radial-gradient(circle at 40px 40px, #ff9800 25%, #563c56 93%, #8b008bd9 5% );
    }

    .la_ball.ten,
    .la_ball.thirty,
    .la_ball.thNine {
        background: radial-gradient(#eba13b, #2c2929a1);
    }

    .main_ball.eleven,
    .main_ball.twenty,
    .main_ball.ttwo {
        background: radial-gradient(circle at 40px 40px, #a32f06 25%, #463446 93%, #8b008bd9 5% );
    }

    .la_ball.eleven,
    .la_ball.twenty,
    .la_ball.ttwo {
        background: radial-gradient(#903013, #00000091);
    }

    .main_ball.twelve,
    .main_ball.ththree {
        background: radial-gradient(circle at 40px 40px, #07685f 25%, #2a3030 93%, #8b008bd9 5% );
    }

    .la_ball.twelve,
    .la_ball.ththree {
        background: radial-gradient(#0a635b, #00000087);
    }

    .main_ball.fOne {
        background: radial-gradient(circle at 40px 40px, #fcf9fd 25%, #181118 93%, #8b008bd9 5% );
    }

    .la_ball.fOne {
        background: radial-gradient(#534e54, #00000033);
    }

    .main_ball.fourteen,
    .main_ball.thSeven
     {background: radial-gradient(circle at 40px 40px, #a7d8de 25%, #1e1c1e 93%, #8b008bd9 5% );}

    .la_ball.fourteen,
    .la_ball.thSeven {
        background: radial-gradient(#a8cbd0, #00000059);
    }

    @media (max-width: 650px) {
        .main_ball {
            width: 40px !important;
            height: 40px !important;
        }

        .se_ball {
            margin-top: 2px;
        }
    }
</style>